// extracted by mini-css-extract-plugin
export var body = "HomeCard-module--body--d7594";
export var children = "HomeCard-module--children--dd759";
export var image = "HomeCard-module--image--d103d";
export var imageContent = "HomeCard-module--imageContent--8a474";
export var leftAligned = "HomeCard-module--leftAligned--31776";
export var rightAligned = "HomeCard-module--rightAligned--5376a";
export var scrollTextContainer = "HomeCard-module--scrollTextContainer--5950a";
export var subtitle = "HomeCard-module--subtitle--4e2e2";
export var text = "HomeCard-module--text--53024";
export var textContent = "HomeCard-module--textContent--e85da";
export var textHero = "HomeCard-module--textHero--b547b";
export var titleContainer = "HomeCard-module--titleContainer--e88e4";