// Colors
const colors = {
  purpleDark: '#150231',
  purple: '#8940f7',
  purpleSoft: '#d2b6fc',
  purpleLight: '#f3ebfe',

  greenDark: '#02b181',
  green: '#58e4b1',
  greenSoft: '#90ffe3',
  greenLight: '#d6fff5',

  redDark: '#c74139',
  red: '#ff7264',
  redSoft: '#ffa492',
  redLight: '#ffddd6',
  pinkDark: '#c9a3c0',
  pink: '#fcd4f3',
  pinkSoft: '#feebfa',
  pinkLight: '#fff5fd',

  yellowDark: '#c9bd22',
  yellow: '#ffef5a',
  yellowSoft: '#ffff8d',
  yellowLight: '#ffffd6',

  blueDark: '#64b5cc',
  blue: '#97e7ff',
  blueSoft: '#cbffff',
  blueLight: '#f5ffff',

  white: '#ffffff',
  gray100: '#fafafa',
  gray200: '#dbdad9',
  gray300: '#b2b0ae',
  gray500: '#5c5957',
  gray700: '#1e1e1e',
  gray900: '#0f0f0f',

  infoDefault: '#2979ff',
  infoDark: '#004ecb',
  infoLight: '#75a7ff',

  success: '#00c853',
  successDark: '#009624',
  successLight: '#5efc82',

  alert: '#ffea00',
  alertDark: '#c7b800',
  alertLight: '#ffff56',

  error: '#f44336',
  errorDark: '#ba000d',
  errorLight: '#ff7961',
};

export default colors;
