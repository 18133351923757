import React from 'react';

import * as styles from './HomeCard.module.scss';
import classNames from 'classnames';
import Markdown from '../markdown';
import { ContentfulImage } from '@src/hooks/useContentfulImage.hook';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

type HomeCardProps = Omit<React.HTMLProps<HTMLElement>, 'size'> & {
  title: string;
  subtitle?: string;
  body?: string;
  image?: ContentfulImage;
  className?: string;
  flip?: boolean;
};

function HomeCard({
  title,
  subtitle,
  body,
  image,
  children,
  className,
  flip = false,
  ...rest
}: HomeCardProps) {
  const classes = classNames({
    [styles.textHero]: true,
    [styles.leftAligned]: !flip,
    [styles.rightAligned]: flip,
    [className]: className,
  });

  const textContentClasses = classNames({
    [styles.leftAligned]: !flip,
    [styles.textContent]: true,
  });

  const TextContent = (
    <div className={textContentClasses}>
      <div className={styles.titleContainer}>
        <h1>
          <ReactMarkdown>{title.replaceAll('<br>', '  \n')}</ReactMarkdown>
        </h1>
        {subtitle && (
          <Markdown className={styles.subtitle}>{subtitle}</Markdown>
        )}
      </div>
      {body && <Markdown className={styles.body}>{body}</Markdown>}
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );

  const ImageContent = (
    <div className={styles.imageContent}>
      {image && (
        <GatsbyImage
          image={image.imageData}
          alt={image.title}
          objectFit="cover"
          className={styles.image}
        />
      )}
    </div>
  );

  return (
    <header className={classes} {...rest}>
      {flip ? (
        <>
          {ImageContent}
          {TextContent}
        </>
      ) : (
        <>
          {TextContent}
          {ImageContent}
        </>
      )}
    </header>
  );
}

export default HomeCard;
