import React from 'react';

function TrustPilotDesktop() {
  return (
    <div
      className="trustpilot-widget"
      data-locale="sv-SE"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5e1db5ace2e67b0001bb8d2d"
      data-style-width="100%"
      data-style-height="100px"
      data-theme="light"
    >
      <a
        href="https://se.trustpilot.com/review/rocker.com"
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}

export default TrustPilotDesktop;