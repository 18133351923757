import Container from '@components/Container';
import React from 'react';
// import useAppStoreRatings from '../../hooks/useAppStoreRatings';
import { ContentfulImage } from '../../hooks/useContentfulImage.hook';
import ChevronDown from '../../icons/chevron-down-black.svg';
import useIsMobile from '../../hooks/useIsMobile.hook';

import * as styles from './HomeHero.module.scss';
import DownloadAppButton from '@components/DownloadAppButton/DownloadAppButton';
import { Rating } from '@mui/material';
import { Star } from '@mui/icons-material';
import Phone from '@src/images/2.0/iphone_14.svg';
import AppRatings from './AppRatings';

type HomeHeroProps = React.HTMLProps<HTMLDivElement> & {
  title: string;
  mobileHero?: ContentfulImage;
};

function HomeHero({ title }: HomeHeroProps) {
  // const ratings = useAppStoreRatings();

  const isMobile = useIsMobile();
  const appDownloadUrl = `https://app.rocker.com/QyXr`;

  return (
    <>
      <div className={styles.heroWrapper}>
        <Container className={styles.hero}>
          <div className={styles.textContainer}>
            <h1>{title}</h1>
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
            <div className={styles.appBadgesAndDisclaimerContainer}>
              <div className={styles.appBadges}>
                <a href={appDownloadUrl}>
                  <img
                    className={styles.googleAppBadge}
                    alt="Ladda ned på Google Play"
                    height={60}
                    width={150}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png"
                  />
                </a>
                <a href={appDownloadUrl}>
                  <img
                    className={styles.appleAppBadge}
                    alt="Hämta i App Store"
                    height={48}
                    width={150}
                    src="https://storage.googleapis.com/rocker-marketing-assets/Download_on_the_App_Store_Badge_SE_RGB_blk_100317.svg"
                  />
                </a>
              </div>
            </div>

            {isMobile && (
              <div className={styles.mobileRatings}>
                <p>Gör som 394 000+ andra&nbsp;&nbsp;</p>
                <Rating
                  name="half-rating-read"
                  readOnly
                  value={4.6}
                  precision={0.5}
                  max={5}
                  sx={{
                    color: '#FFEF5A',
                    fontSize: '1rem',
                    paddingRight: '6px',
                  }}
                  emptyIcon={
                    <Star
                      style={{ opacity: 1, color: '#999999' }}
                      fontSize="inherit"
                    />
                  }
                />
                <p>
                  <a href="https://www.trustpilot.com/review/rocker.com">
                    Läs omdömen
                  </a>
                </p>
              </div>
            )}

            {isMobile && (
              <div className={styles.scrollTextContainer}>
                <div className={styles.text}>Allt bra med Rocker</div>
                <ChevronDown />
              </div>
            )}
          </div>
          {!isMobile && <img src={Phone} className={styles.imageWrapper} />}
        </Container>
        {!isMobile && (
          <div className={styles.blueBanner}>
            Gör som 394 000+ andra&nbsp;&nbsp;
            <Rating
              name="half-rating-read"
              readOnly
              value={4.6}
              precision={0.5}
              max={5}
              sx={{ color: '#FFEF5A', fontSize: '1rem', paddingRight: '6px' }}
              emptyIcon={
                <Star
                  style={{ opacity: 1, color: '#999999' }}
                  fontSize="inherit"
                />
              }
            />
            <a href="https://www.trustpilot.com/review/rocker.com">
              Läs omdömen
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default HomeHero;
