import React from 'react';

import * as styles from './RockerPayHomeCard.module.scss';
import classNames from 'classnames';
import Markdown from '../markdown';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import RockerPayFormCard from './components/RockerPayFormCard/RockerPayFormCard';

type RockerPayHomeCardProps = Omit<React.HTMLProps<HTMLElement>, 'size'> & {
  title: string;
  subtitle?: string;
  body?: string;
  className?: string;
  flip?: boolean;
};

function RockerPayHomeCard({
  title,
  subtitle,
  body,
  children,
  className,
  flip = false,
  ...rest
}: RockerPayHomeCardProps) {
  const classes = classNames({
    [styles.textHero]: true,
    [styles.leftAligned]: !flip,
    [styles.rightAligned]: flip,
    [className]: className,
  });

  const textContentClasses = classNames({
    [styles.leftAligned]: !flip,
    [styles.textContent]: true,
  });

  const TextContent = (
    <div className={textContentClasses}>
      <div className={styles.titleContainer}>
        <h1>
          <ReactMarkdown>{title.replaceAll('<br>', '  \n')}</ReactMarkdown>
        </h1>
        {subtitle && (
          <Markdown className={styles.subtitle}>{subtitle}</Markdown>
        )}
      </div>
      {body && <Markdown className={styles.body}>{body}</Markdown>}
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );

  return (
    <header className={classes} {...rest}>
        <>
          {TextContent}
          <div className={styles.imageContent}>
            <RockerPayFormCard />
        </div>
        </>
    </header>
  );
}

export default RockerPayHomeCard;
