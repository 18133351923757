// extracted by mini-css-extract-plugin
export var body = "RockerPayHomeCard-module--body--b33e9";
export var children = "RockerPayHomeCard-module--children--35a70";
export var image = "RockerPayHomeCard-module--image--ff7ff";
export var imageContent = "RockerPayHomeCard-module--imageContent--6e962";
export var leftAligned = "RockerPayHomeCard-module--leftAligned--50e03";
export var rightAligned = "RockerPayHomeCard-module--rightAligned--a7b26";
export var scrollTextContainer = "RockerPayHomeCard-module--scrollTextContainer--20435";
export var subtitle = "RockerPayHomeCard-module--subtitle--32211";
export var text = "RockerPayHomeCard-module--text--2ff10";
export var textContent = "RockerPayHomeCard-module--textContent--d8367";
export var textHero = "RockerPayHomeCard-module--textHero--b3770";
export var titleContainer = "RockerPayHomeCard-module--titleContainer--f6058";