import { isExternalLink } from '@src/lib/utils';
import classNames from 'classnames';
import { GatsbyLinkProps } from 'gatsby';
import { Link } from 'gatsby-plugin-intl';
import React from 'react';

import * as styles from './TextLink.module.scss';

interface TextLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  to?: string;
  large?: boolean;
}

function TextLink({ to, large, children, className, ...rest }: TextLinkProps) {
  const classes = classNames({
    [styles.link]: true,
    [styles.large]: large,
    [className]: className,
  });

  if (isExternalLink(to)) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        className={classes}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...rest} className={classes}>
      {children}
    </Link>
  );
}

export default TextLink;
