import React from 'react';
import styled from '@emotion/styled';
import BlocketSvg from '@src/images/partners/blocket-2.svg';
import { Divider, Typography } from '@mui/material';
import PlickSvg from '@src/images/partners/plick.svg';
import TrustPilotDesktop from './TrustPilotDesktop';
import TrustPilotMobile from './TrustPilotMobile';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  padding: 24px;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 600px) {
      flex-direction: row;
    }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;

  & p {
    text-align: left;
    margin-right: 12px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & img {
    height: 24px;
    margin-right: 12px;
  }

  & svg {
    height: 24px;
    margin-right: 12px;
  }
`;

const TrustPilotWrapper = styled.div`
  padding-left: 0px;
  padding-top: 24px;
  @media (min-width: 600px) {
    padding-left: 24px;
  }
`;

const TrustedBySection = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  return (
    <Container>
      <Content>
        <Wrapper>
          <Typography variant='body1' mb={1}>
            Rocker Pay används årligen för hundratusentals affärer mellan
            privatpersoner. Vår betallösning används bland annat på:
          </Typography>
          <IconWrapper>
            <img style={{ height: 18 }} src={BlocketSvg} alt="Blocket" />
            <img style={{ height: 24 }} src={PlickSvg} alt="Plick" />
          </IconWrapper>
        </Wrapper>
        <Divider orientation="vertical" flexItem />
        <TrustPilotWrapper>
          {isSmallScreen ? <TrustPilotMobile /> : <TrustPilotDesktop />}
        </TrustPilotWrapper>
      </Content>
    </Container>
  )
};

export default TrustedBySection;
