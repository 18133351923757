import { TextField, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useState } from 'react';
import PrimaryButton from '../../../PrimaryButton/PrimaryButton';
import styled from '@emotion/styled';
import TrustedBySection from './TrustedBySection';

const PAY_URL = process.env.GATSBY_PAY_BASE_URL;
const PAY_API_URL = `${PAY_URL}/api`;

const Divider = styled.div`
  height: 1px;
  background-color: #E4D2FD;
`;

const TextWrapper = styled.div`
  align-self: center;
  margin-right: 4;
  @media (max-width: 600px) {
    display: none;
  `;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
  }
`

const Input = styled(TextField) <{ value: string }>`
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
  }

  & .MuiFilledInput-root {
    background-color: #F5F5F8;
    &:hover {
      background-color: #ECECFF;
      &:before {
        border-bottom: 3px solid #8940F7 !important;
      }
      &:after {
        && {
          border-bottom: 3px solid #8940F7 !important;
        }
      }
    }
    &:before {
      border-bottom: 3px solid #BBB7D1;
    }
    &:after {
      border-bottom: 3px solid #8940F7;
    }
  }

  & .MuiFilledInput-input {
    color: #494283;
    font-family: 'AeonikPro-Medium';

    &::placeholder {
      color: #736E7A;
      font-size: 16px;
    }

    flex-shrink: 1;
    flex-grow: 0;
    background-color: #f2f3f7;
    padding: 8px 8px;
    padding-bottom: 7px;
    border-radius: 3px;
    border: none;
    border-bottom: 3px solid #494283;
    outline: none;
    font-weight: 500;
    width: 100%;

    font-size: 20px;
    line-height: 120%;
    color: #494283;
    margin-right: 8px;

    &:nth-child(3) {
      margin-right: 12px;
    }
  
    &:last-child {
      margin-right: 0px;
    }
  
    &:hover {
      color: #8940F7;
    }
    &:focus {
      background-color: #ECECFF;
      color: #8940F7;
    }

  }

  & .MuiInputLabel-root.Mui-focused {
    color: #8940F7;
  }

  & .MuiFilledInput-root.Mui-error {
    &:after,
    &:before {
      border-color: #f44336;
    }
  }

  & .MuiInputLabel-root.Mui-error {
    color: #f44336;
  }

  & .MuiFormHelperText-root {
    color: #736E7A;
    font-size: 12px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  max-width: 520px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Section = styled.div`
  margin-bottom: 12px;
  padding: 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-height: 48px;
  align-items: baseline;
`;

const RockerPayFormCard = () => {
  const [rubrik, setRubrik] = useState('');
  const [code, setCode] = useState('');
  const [isCodeInputError, setIsCodeInputError] = useState(false);

  const handleCodeSubmit = async (input: string) => {
    if (!input) {
      setIsCodeInputError(true);
      return;
    }

    setIsCodeInputError(false);

    const offer = await getOfferByCode(input ?? code);

    if (offer.id) {
      window
        .open(
          `${PAY_URL}/o/${offer.id}`,
        )
        .focus();
    } else {
      setIsCodeInputError(true);
    }

    setCode('');
  }

  const handleCodeSubmitWithCode = async () => {
    if (!code || code.length !== 6) {
      setIsCodeInputError(true);
      return;
    }

    handleCodeSubmit(code);
  }

  const handleCodePaste = (e: React.ClipboardEvent) => {
    const code = e.clipboardData.getData('text');
    e.preventDefault();
    e.stopPropagation();
    if (code) {
      setIsCodeInputError(false);

      let parsedCode = '';
      if (code.indexOf('Koden till betallänken') < 0) {
        const filteredData = code.replaceAll(' ', '').substr(0, 6);
        parsedCode = filteredData;
      } else {
        const start = 'Koden till betallänken är: ';
        const index = code.indexOf(start);
        parsedCode = code.substr(index + start.length, 6);
      }

      setCode(parsedCode);

      if (parsedCode.length === 6) {
        handleCodeSubmit(parsedCode);
      }
    }
  };

  const handleRubrikSubmit = async () => {
    // Redirect to an external page
    window
      .open(
        `${PAY_URL}/create?title=${rubrik}`,
      )
      .focus();

    setRubrik('');
  }


  const getOfferByCode = async (code: string): Promise<any> => {
    const resp = await fetch(`${PAY_API_URL}/v1/offers?manualCode=${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-MerchantId': process.env.GATSBY_ROCKER_MERCHANT_ID,
      },
    });

    const data = await resp.json();
    return data;
  };

  const handleCodeInputChange = (input: any) => {
    if (input.target.value.length > 6) return;

    setIsCodeInputError(false);
    setCode(input.target.value);
    if (input.target.value.length === 6) handleCodeSubmit(input.target.value);

  };

  const handleRubrikInputChange = (input: any) => {
    if (input.target.value.length > 50) return;

    setRubrik(input.target.value);
  }


  return (
    <Container>
      {/* First section */}
      <Section>
        <HeaderWrapper>
          <div>
            <h3 style={{ flex: 1, marginBottom: 16 }}>
              Köp säkert
            </h3>
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              Har du fått en betalningskod? Skriv in den för att komma till betalning av varan
            </Typography>
          </div>
        </HeaderWrapper>
        <InputWrapper>
          <Input
            placeholder="6-siffrig kod"
            onChange={handleCodeInputChange}
            variant="filled"
            value={code}
            error={isCodeInputError}
            helperText={isCodeInputError ? "Felaktig kod" : null}
            onPaste={handleCodePaste}
          />
          <PrimaryButton style={{ paddingRight: 12, paddingLeft: 12, alignItems: 'center', alignContent: 'center', textAlign: 'center', justifyContent: 'center' }} onClick={handleCodeSubmitWithCode} color="purple" >
            <TextWrapper>
              Köp säkert med frakt
            </TextWrapper>
            <ArrowForwardIcon style={{ alignSelf: 'center' }} />
          </PrimaryButton>
        </InputWrapper>
      </Section>
      <Divider />
      {/* Second section */}
      <Section>
        <HeaderWrapper>
          <div>
            <h3 style={{ flex: 1, marginBottom: 16 }}>
              Sälj enkelt
            </h3>
          </div>
          <div style={{ flex: 1 }}>
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              Vill du sälja, frakta och ta betalt  av en annan person på ett tryggt sätt? Kom igång enkelt
            </Typography>
          </div>
        </HeaderWrapper>
        <InputWrapper>
          <Input
            placeholder="Rubrik"
            onChange={handleRubrikInputChange}
            variant="filled"
            helperText={`Beskriv kortfattat vad du säljer. ${rubrik.length}/50`}
            value={rubrik}
          />
          <PrimaryButton onClick={handleRubrikSubmit} color="purple" style={{ paddingRight: 12, paddingLeft: 12, alignItems: 'center', alignContent: 'center', textAlign: 'center', justifyContent: 'center' }}>
            <TextWrapper>
              Sälj säkert med frakt
            </TextWrapper>
            <ArrowForwardIcon style={{ alignSelf: 'center' }} />
          </PrimaryButton>
        </InputWrapper>
      </Section>
      <Divider />
      {/* Third section */}
      <TrustedBySection />
    </Container>
  )
};

export default RockerPayFormCard;